export const SUB_EDITION_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment subEditionGenericPageHeaderFragment on SubEdition {
    uid
    name
    startTime
    startTimestamp
    endTime
    endTimestamp
    venue {
      name
      address {
        city
        state
        country {
          name
          iso
        }
      }
    }
    __typename
    bannerFileResource {
      schemaCode
      path
    }
    logoFileResource {
      schemaCode
      path
    }
    _followerCount
    autoFollow
    archived
  }
`;
